<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'medical-illustration',
  primary_color: '#005F7F',
  secondary_color: '#96FFE0',
  header: {
    background: {
      image: `/projekt/medical-illustration/elbworx-projekt-medical-pneumologie_unsere_lösung-2639.webp`,
    },
    project_class: 'text-elbworx-black',
    headline_class: 'text-[#00AF90]',
    project: 'Pharma Marketing',
    headline: 'Medical Illustration',
    info_card: {
      description:
        'Anatomische Zusammenhänge veranschaulichen, pharmazeutische Wirkmechanismen erklären.',
      client: 'Verschiedene international tätige pharmazeutische Unternehmen',
      roles: ['Illustration', 'Pharma-Marketing', 'Infografik'],
      project_duration: 'seit 2014',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3 class="text-primary">Spezifische Herausforderungen im Pharma-Marketing</h3>

      <p>
        Geht es um verschreibungspflichtige Medikamente, so ist Publikumswerbung (nicht nur in
        Deutschland) unzulässig. Marketing für rezeptgebundene Pharma-Präparate wendet sich daher
        zuallererst an
        <strong>Ärzte</strong>
        — sie sind es, die das Produkt „kaufen“, indem sie es ihren Patienten verordnen. Ärzte sind
        darauf angewiesen, regelmäßig über neue Therapie-Möglichkeiten informiert zu werden. Dies
        gilt in besonderem Maße für Allgemeinmediziner, die ein wesentlich breiteres Spektrum an
        Therapiegebieten abdecken müssen als Fachärzte.
      </p>
      <p>
        Gespräche mit
        <strong>Pharma-Referenten</strong>
        bieten Ärzten die Gelegenheit, sich über therapeutische Innovationen oder neue
        wissenschaftliche Erkenntnisse (z. B. Studien) zu informieren. Allerdings können viele Ärzte
        aufgrund der hohen Auslastung ihrer Praxen nur wenig Zeit für derartige Gespräche
        bereitstellen. Umso wichtiger ist es für den Referenten, dem Arzt in nur wenigen Minuten
        eine optimal vorbereitete Produktbotschaft zu präsentieren: kurzweilig, wissenschaftlich
        fundiert, klar und übersichtlich gestaltet, inhaltlich auf den Punkt.
      </p>
    </template>

    <div class="absolute inset-x-0 -mt-12 mb-8">
      <Padding class="flex justify-center">
        <hr class="h-px w-full max-w-container border-gray-400" />
      </Padding>
    </div>

    <Padding>
      <Grid class="items-start">
        <div class="col-span-8">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-medical-allergologie-1800.webp`"
          />
        </div>
        <div class="col-span-4">
          <Text class="md:text-right">
            <h3>Beispiel Allergologie</h3>
            <p>
              Darstellung der allergischen „Kettenreaktion“ vom ersten Allergen-Kontakt bis zur
              sofortigen oder (chronischen) Spätreaktion des Körpers.
            </p>
          </Text>
        </div>
      </Grid>
    </Padding>
    <Padding only="left" class="mt-10">
      <Image
        class="w-full object-cover"
        :src="`${projectPath}elbworx-projekt-medical-allergologie2-2160.webp`"
      />
    </Padding>

    <Padding class="mt-14">
      <Grid class="items-start">
        <div class="flex md:col-span-2 md:justify-end">
          <div class="w-fit bg-elbworx-black px-2 py-1 text-white">vorher</div>
        </div>

        <Text class="md:col-span-10 md:row-start-1">
          <h3>Beispiel Adipositas-Therapie</h3>
        </Text>
        <Text class="md:col-span-4 md:row-start-2">
          <p>
            Regulierung von Sättigung und Hunger als Schlüssel zu erfolgreichem Abnehmen: Zwei
            separate Verlaufskurven wurden abstrahiert und in einer einzigen Grafik mit
            „High-Tech“-Anmutung gebündelt.
          </p>
        </Text>

        <div class="md:col-span-8">
          <div class="flex gap-8">
            <div>
              <Image
                class="w-full"
                :src="`${projectPath}elbworx-projekt-medical-adipositas_vorher1-960.webp`"
              />
            </div>
            <div>
              <Image
                class="w-full"
                :src="`${projectPath}elbworx-projekt-medical-adipositas_vorher2-960.webp`"
              />
            </div>
          </div>
        </div>
      </Grid>
    </Padding>

    <Padding only="right" class="mt-14">
      <div class="relative">
        <Padding class="md:contents">
          <div class="left-24 top-12 mb-8 w-fit bg-elbworx-yellow px-2 py-1 font-mono md:absolute">
            unsere Lösung
          </div>
        </Padding>
        <Image
          class="w-full"
          :src="`${projectPath}elbworx-projekt-medical-adipositas_unsere_lösung-2560.webp`"
        />
      </div>
    </Padding>

    <Padding class="mt-14">
      <Text class="md:ml-52">
        <h3>Klarheit ist Trumpf</h3>
      </Text>
      <div class="flex justify-center">
        <Text>
          <p>
            Infografiken spielen eine Schlüsselrolle bei der Erklärung von Wirkweise und
            Therapiepotenzial eines Medikaments. Wichtige und zumeist komplexe Sachverhalte müssen
            so präsentiert werden, dass sie vom Betrachter schnell und intuitiv erfasst werden; dies
            gilt für anatomische Darstellungen ebenso wie für die Präsentation empirischer Daten.
            Oft ist es jedoch der Wunsch nach wissenschaftlicher Genauigkeit und Detailtreue, der
            dieses Anliegen konterkariert und am Ende zu überfrachteten Darstellungen führt, die ­—
            gemessen an der Kürze der Zeit ­— mehr verbergen, als sie enthüllen. Wie so oft gilt
            auch für medizinische Infografiken die Faustregel: Weniger ist im Zweifel mehr; je
            klarer und übersichtlicher, umso besser. Um dieses Ziel zu erreichen, müssen Farb- und
            Formensprache, Typografie, Maßstäblichkeit und Detaillierung der Grafik sorgfältig
            gewählt werden.
          </p>
          <p>
            <strong>
              Als Agentur mit Pharma-Spezialisierung verfügen wir über langjährige Erfahrung in der
              Konzeption von Marketing-Materialien für verschreibungspflichtige Arzneien. Wir sind
              Experten darin, medizinisch-anatomische Sachverhalte wissenschaftsgetreu abzubilden
              und zugleich mit einer zeitgemäßen Ästhetik zu versehen, die Kompetenz und Anspruch
              des pharmazeutischen Unternehmens unterstreichen.
            </strong>
          </p>
        </Text>
      </div>
    </Padding>

    <Padding class="my-8">
      <hr class="h-px border-gray-400" />
    </Padding>

    <Padding>
      <Text class="!max-w-none">
        <h3>Ophthalmologie: Erklärung der altersbedingten Makula-Degeneration für Patienten</h3>
      </Text>
    </Padding>
    <Padding class="bg-[#FEF2D9] py-16">
      <figure class="flex flex-col gap-4">
        <div class="relative flex justify-evenly gap-4">
          <Image
            class="w-0 flex-1"
            :src="`${projectPath}elbworx-projekt-medical-ophthalmologie_vorher1-520.webp`"
          />
          <Image
            class="w-0 flex-1"
            :src="`${projectPath}elbworx-projekt-medical-ophthalmologie_vorher2-520.webp`"
          />
          <Image
            class="w-0 flex-1"
            :src="`${projectPath}elbworx-projekt-medical-ophthalmologie_vorher3-520.webp`"
          />
          <Image
            class="w-0 flex-1"
            :src="`${projectPath}elbworx-projekt-medical-ophthalmologie_vorher4-520.webp`"
          />
          <div class="absolute right-0 top-0 w-fit bg-elbworx-black px-2 py-1 font-mono text-white">
            vorher
          </div>
        </div>

        <Text class="!max-w-none">
          <figcaption>
            <i>
              In augenheilkundlichen Informationsangeboten finden sich oft
              <strong>kleinteilige 3D-Darstellungen des Krankheitsverlaufs</strong>
              wie diese. Sie zeichnen sich einerseits durch hohe Wirklichkeitstreue, andererseits
              aber auch einen Grad an Detaillierung aus, der die Orientierung erschweren kann.
            </i>
          </figcaption>
        </Text>
      </figure>
    </Padding>

    <Tilt class="relative -mt-tilt pt-tilt">
      <Padding class="md:absolute">
        <div class="mt-10 w-fit bg-elbworx-yellow px-2 py-1 font-mono">unsere Lösung</div>
      </Padding>
      <Image
        class="col-span-12 h-full w-full object-contain"
        :src="`${projectPath}elbworx-projekt-medical-ophthalmologie_unsere_lösung-3840.webp`"
      />
    </Tilt>
    <div class="relative ml-32 w-32 translate-y-40">
      <Diagonals class="absolute right-0 top-0 origin-right rotate-[55deg] bg-[#4DC3B1]" />
    </div>

    <Grid class="md:-mt-tilt">
      <div class="col-span-6 col-end-13 -mt-tilt">
        <Tilt contain class="-mt-tilt bg-[#FDE4B3]">
          <Padding class="flex items-center py-10">
            <Text>
              <h3>Weniger ist oft mehr</h3>
              <p>
                Durch Reduzierung des Netzhaut-Querschnitts auf geometrische Grundformen,
                Verschlankung der Farbpalette sowie Vereinheitlichung der Konturenstärke haben wir
                ein Maß an Abstraktion erreicht, das die degenerativen Veränderungen im Auge klar
                hervortreten lässt ­— ohne dabei die Komplexität dessen Aufbaus zu leugnen. Die
                verschiedenen Vergrößerungsstufen ermöglichen eine schnelle Einordnung der gezeigten
                Details in den übergeordneten anatomischen Kontext.
              </p>
            </Text>
          </Padding>
        </Tilt>
      </div>
    </Grid>

    <Padding class="mt-20">
      <Text class="!max-w-none">
        <h3>
          Darstellung von Krankheit und Therapieansatz:
          <br />
          Ästhetik vs. Wirklichkeitstreue am Beispiel Pneumologie
        </h3>
      </Text>
    </Padding>
    <Padding class="bg-[#F4EFEE] py-10">
      <Grid class="items-start">
        <div class="col-span-7 pr-10">
          <Text class="!max-w-none italic prose-p:text-base">
            <p>
              Bei der Abbildung rechts handelt es sich um eine klassische Gegenüberstellung von
              <strong>Konstriktion</strong>
              (Verengung) sowie
              <strong>Relaxation</strong>
              und
              <strong>Dilatation</strong>
              (Entspannung und Weitung) der
              <strong>Bronchien</strong>
              . Die Illustration ist detailliert ausgearbeitet und verzichtet weitgehend auf
              Abstraktion; sie will die anatomischen Gegebenheiten möglichst wirklichkeitsgetreu
              wiedergeben. Dies trifft auch auf die Farbgebung zu: Da es sich bei der Lunge um ein
              inneres Organ von rosa-fleischfarbener Anmutung handelt, arbeitet auch die Grafik mit
              einer Palette von überwiegend kräftigen Rottönen.
            </p>
            <p>
              Grafiken wie diese eignen sich gut für Schulungs- und Lehrzwecke; für den Einsatz im
              Pharma-Marketing sind sie nur bedingt geeignet: Rot spielt in der
              Wahrnehmungspsychologie eine wichtige Rolle als Signalfarbe; sie warnt vor Gefahr und
              mahnt zum „Stopp“. Allzu realistische, von Rottönen dominierte Darstellungen können
              daher schnell „gefährlich“ wirken. Damit schwächen sie das Anliegen, beim Betrachter
              Vertrauen in Wirksamkeit und Sicherheit des Produkts zu wecken.
            </p>
          </Text>
        </div>
        <div class="col-span-5">
          <div class="relative">
            <Image
              class="object-contain"
              :src="`${projectPath}elbworx-projekt-medical-pneumologie_vorher-888.webp`"
            />
            <div class="absolute right-0 top-0 w-fit bg-elbworx-black px-2 py-1 text-white">
              vorher
            </div>
          </div>
        </div>
      </Grid>
    </Padding>

    <Tilt style="--top-left-y-factor: 0">
      <Padding>
        <div class="relative">
          <div class="left-0 top-8 w-fit bg-elbworx-yellow px-2 py-1 font-mono md:absolute">
            unsere Lösung
          </div>
        </div>
      </Padding>
      <Image
        class="col-span-12 h-full w-full object-contain"
        :src="`${projectPath}elbworx-projekt-medical-pneumologie_unsere_lösung-2639.webp`"
      />
    </Tilt>

    <Padding class="-mt-tilt">
      <Grid>
        <div class="col-span-4 mt-tilt-container">
          <Text class="mt-20">
            <h4>Entwicklung einer schematisierten Darstellung von Lunge und Bronchialbaum</h4>
          </Text>
        </div>
        <div class="col-span-8 mt-tilt">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-medical-entwicklung_lungenschema-1600.webp`"
          />
        </div>

        <div class="col-span-6 md:mr-4">
          <Text>
            <p>
              Um die komplexen Verästelungen des Bronchialbaums abzubilden, haben wir ein System
              grafischer Module entwickelt, die von Symmetrie und Rhythmus durchdrungen sind. Das
              Gewirr der bronchialen Verzweigungen erscheint geordnet und strukturiert; die Lunge
              erhält eine „technoide“ Anmutung. Dieser hohe Abstraktionsgrad vermittelt den Eindruck
              der Steuer- und Beeinflussbarkeit des respiratorischen Geschehens:
            </p>
          </Text>
        </div>
        <div class="col-span-6">
          <Text>
            <p>
              Atmung wird als Prozess dargestellt, in den bei Störungen präzise und zielgenau
              eingegriffen werden kann.
            </p>
            <p>
              Auf diese Weise präsentieren wir pharmazeutisches Arbeiten als eine Form von
              „Ingenieurskunst“, die ­— zuverlässig und wissenschaftlich nachprüfbar — eine
              Verbesserung der Symptomatik herbeiführt.
            </p>
          </Text>
        </div>
      </Grid>
    </Padding>
  </Project>
</template>
